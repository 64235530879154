import React, { useState, useEffect } from "react";
import { Table, Card, Space } from "lib";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  TablePaginationConfig,
} from "antd";
import { Layout } from "layout";
import {
  AdministratorBriefDto,
  AdministratorsClient,
  AdministratorType,
  ClientUsersClient,
  DeleteAdministratorCommand,
  ForceUpdateAdministratorPasswordCommand,
  SendResetPasswordEmailCommand,
} from "@api";
import { showError } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import AdministratorMenu from "@components/Admin/Administrator/AdministratorMenu";
import { PlusOutlined } from "@ant-design/icons";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import ResetPasswordModal from "@components/Admin/ResetPasswordModal";
import LanguageSelectFilter from "@components/Admin/LanguageSelectFilter";

const defaultOrderBy = "id";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(
    SECTION.Admin,
    "Administrator",
    "TechAdministrator"
  );
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [createTechAdminForm] = Form.useForm();
  const [editTechAdminForm] = Form.useForm();

  const administratorsClient = new AdministratorsClient();
  const clientUsersClient = new ClientUsersClient();

  const [state, setState] = useState({
    items: [] as AdministratorBriefDto[],
    pagination: defaultPagination,
    loading: false,
  });
  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );
  const [createTechAdminModalVisible, setCreateTechAdminModalVisible] =
    useState(false);
  const [editTechAdminModalVisible, setEditTechAdminModalVisible] =
    useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] =
    useState(false);
  const [resetPasswordUserId, setResetPasswordUserId] = useState("");

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "TechAdministrator")) return;

    setState({
      ...state,
      loading: true,
    });
    try {
      const res = await administratorsClient.getAdministratorWithPagination(
        _keywords ?? keywords,
        AdministratorType.Technical,
        params.pagination.current,
        params.pagination.pageSize,
        capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
        (params.sortOrder ?? defaultSortOrder) == "ascend"
      );
      setState({
        ...state,
        items:
          (res.items as AdministratorBriefDto[]) ||
          ([] as AdministratorBriefDto[]),
        pagination: {
          ...params.pagination,
          total: res.totalCount,
        },
        loading: false,
      });
    } catch (err) {
      setState({
        ...state,
        loading: false,
      });
      showError(err);
    }
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleAddTechAdministrator() {
    try {
      const values = await createTechAdminForm.validateFields();
      if (values) {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticCreatingMessage" />
        );
        try {
          await administratorsClient.create(values);
          messageApi.success(
            <FormattedMessage id="StaticCreateSuccessMessage" />
          );
          setCreateTechAdminModalVisible(false);
          createTechAdminForm.resetFields();
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      }
    } catch (err) {
      /* form error block */
    }
  }

  function handleCancelAddTechAdministrator() {
    setCreateTechAdminModalVisible(false);
    createTechAdminForm.resetFields();
  }

  function OpenModalEditTechAdministrator(record: AdministratorBriefDto) {
    setEditTechAdminModalVisible(true);
    editTechAdminForm.setFieldsValue(record);
  }

  async function handleEditTechAdministrator() {
    try {
      const values = await editTechAdminForm.validateFields();
      if (values) {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await administratorsClient.update(values);
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          setEditTechAdminModalVisible(false);
          editTechAdminForm.resetFields();
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      }
    } catch (err) {
      /* form error block */
    }
  }

  function handleCancelEditTechAdministrator() {
    setEditTechAdminModalVisible(false);
    editTechAdminForm.resetFields();
  }

  function OpenModalResetPassword(id: string) {
    setResetPasswordModalVisible(true);
    setResetPasswordUserId(id);
  }

  const handleResetPassword = async (data: any) => {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUpdatingMessage" />
    );
    setResetPasswordModalVisible(false);

    try {
      if (data.type === 1)
        await clientUsersClient.sendResetPasswordEmail(
          new SendResetPasswordEmailCommand({
            userId: resetPasswordUserId,
            defaultLanguageId: data.defaultLanguageId,
          })
        );
      else
        await administratorsClient.forceChangePassword(
          new ForceUpdateAdministratorPasswordCommand({
            userId: resetPasswordUserId,
            newPassword: data.newPassword,
          })
        );
      messageApi.success(<FormattedMessage id="StaticUpdateSuccessMessage" />);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  };

  const handleCancelResetPassword = () => {
    setResetPasswordModalVisible(false);
  };

  function handleDelete(id: string) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmDeleteMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticDeletingMessage" />
        );
        try {
          await administratorsClient.detele(
            new DeleteAdministratorCommand({ id })
          );
          messageApi.success(
            <FormattedMessage id="StaticDeleteSuccessMessage" />
          );
          fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    await fetchItems(undefined, values.keywords);
  }

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    //   defaultSortOrder,
    // },
    {
      // title: "User Name",
      title: <FormattedMessage id="TechnicalTableHeaderUserName" />,
      dataIndex: "userName",
      key: "userName",
      sorter: true,
    },
    {
      // title: "Full Name",
      title: <FormattedMessage id="TechnicalTableHeaderFullName" />,
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
    },
    {
      // title: "Preferred Language",
      title: <FormattedMessage id="TechnicalTableHeaderLanguage" />,
      dataIndex: "preferredLanguageDisplayName",
      key: "preferredLanguageDisplayName",
      sorter: true,
    },
    {
      // title: "Email",
      title: <FormattedMessage id="TechnicalTableHeaderEmail" />,
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      // title: "Action",
      title: <FormattedMessage id="TechnicalTableHeaderAction" />,
      render: (text: any, record: AdministratorBriefDto) => (
        <Space>
          {auth.can(ACTION.Edit, "TechAdministrator") ? (
            <Button
              type="primary"
              onClick={() => OpenModalEditTechAdministrator(record)}
            >
              <Space>
                <FormattedMessage id="AdministratorTechAdministratorTableEditButton" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Delete, "TechAdministrator") ? (
            <Button
              type="primary"
              onClick={() => handleDelete(record.id as string)}
            >
              <Space>
                <FormattedMessage id="StaticDeleteButton" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Send, "ResetPassword") ? (
            <Button
              type="primary"
              onClick={() => OpenModalResetPassword(record.id as string)}
            >
              <Space>
                <FormattedMessage id="StaticResetPasswordButton" />
              </Space>
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Layout title="AdministratorTechAdministratorNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <AdministratorMenu />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={handleKeywordsSearch}
              >
                <Form.Item name="keywords">
                  <Input placeholder={StaticKeywords} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Space>
            <Space wrap>
              {auth.can(ACTION.Create, "TechAdministrator") ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateTechAdminModalVisible(true);
                  }}
                >
                  <Space>
                    <PlusOutlined />
                    <FormattedMessage id="AdministratorTechAdministratorContentAddButton" />
                  </Space>
                </Button>
              ) : null}
            </Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      <Modal
        visible={createTechAdminModalVisible}
        title={
          <FormattedMessage id="AdministratorTechAdministratorModalAddTitle" />
        }
        okText={<FormattedMessage id="StaticCreateButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleAddTechAdministrator}
        onCancel={handleCancelAddTechAdministrator}
        destroyOnClose={true}
      >
        <Form
          layout="horizontal"
          form={createTechAdminForm}
          {...formItemLayout}
        >
          <Form.Item
            name="userName"
            label={
              <FormattedMessage id="AdministratorAdministratorStaticLoginIDLabel" />
            }
            rules={[]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={
              <FormattedMessage id="AdministratorAdministratorStaticPasswordLabel" />
            }
            rules={[]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="fullName"
            label={
              <FormattedMessage id="AdministratorAdministratorStaticFullNameLabel" />
            }
            rules={[]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={
              <FormattedMessage id="AdministratorAdministratorStaticEmailLabel" />
            }
            rules={[
              {
                type: "email",
                message: (
                  <FormattedMessage id="AdministratorAdministratorStaticInvalidEmailRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="preferredLanguageId"
            label={
              <FormattedMessage id="AdministratorAdministratorStaticLanguageLabel" />
            }
            rules={[]}
          >
            <LanguageSelectFilter
              isEnabled={true}
              allowClear={false}
              handleAction={(value) =>
                createTechAdminForm.setFieldsValue({
                  preferredLanguageId: value,
                })
              }
            />
          </Form.Item>
          <Form.Item
            name="type"
            hidden
            initialValue={AdministratorType.Technical}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={editTechAdminModalVisible}
        title={
          <FormattedMessage id="AdministratorTechAdministratorModalEditTitle" />
        }
        okText={<FormattedMessage id="StaticUpdateButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleEditTechAdministrator}
        onCancel={handleCancelEditTechAdministrator}
        destroyOnClose={true}
      >
        <Form layout="horizontal" form={editTechAdminForm} {...formItemLayout}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="userName"
            label={
              <FormattedMessage id="AdministratorAdministratorStaticLoginIDLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="AdministratorAdministratorStaticMissingLoginIDRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="fullName"
            label={
              <FormattedMessage id="AdministratorAdministratorStaticFullNameLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="AdministratorAdministratorStaticMissingFullNameRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={
              <FormattedMessage id="AdministratorAdministratorStaticEmailLabel" />
            }
            rules={[
              {
                type: "email",
                message: (
                  <FormattedMessage id="AdministratorAdministratorStaticInvalidEmailRule" />
                ),
              },
              {
                required: true,
                message: (
                  <FormattedMessage id="AdministratorAdministratorStaticMissingEmailRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="preferredLanguageId"
            label={
              <FormattedMessage id="AdministratorAdministratorStaticLanguageLabel" />
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="AdministratorAdministratorStaticMissingLanguageRule" />
                ),
              },
            ]}
          >
            <LanguageSelectFilter
              isEnabled={true}
              allowClear={false}
              handleAction={(value) =>
                editTechAdminForm.setFieldsValue({
                  preferredLanguageId: value,
                })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <ResetPasswordModal
        visible={resetPasswordModalVisible}
        handleAction={handleResetPassword}
        handleCancelAction={handleCancelResetPassword}
      />
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
